<template>
  <div class="container-fliud">
    <div class="row">
      <div class="col-12 m-0 p-0">
        <div class="row justify-content-end m-0 p-0">
          <div class="col-6 col-xl-2 text-xl-right">
            <div class="form-group form-group-sm  ">
              <input
                ref="fileupload"
                type="file"
                class="form-control-file form-control-file-sm"
                @change="onChange"
              />
            </div>
          </div>
          <div class="col-6 col-xl-3 text-xl-right">
            <button
              type="button"
              class="btn btn-sm bt-bnb mx-1"
              @click="Upload()"
              :disabled="items.length === 0"
            >
              บันทึกข้อมูล
            </button>
            <button
              type="button"
              class="btn btn-sm bt mx-1"
              :disabled="items.length === 0"
              @click="Clear()"
            >
              ยกเลิก
            </button>
            <button type="button" class="btn btn-sm bt-main mx-1">
              <a :href="file" download class="text-white"> ไฟล์ตัวอย่าง</a>
            </button>
          </div>
        </div>
      </div>
      <div class="row col-12">
        <div class="col-12  ">
          <p style="font-weight: bold;">
            จำนวน&nbsp;
            <span class="text-main">{{
              new Intl.NumberFormat().format(totalRows)
            }}</span>
            &nbsp;รายการ &nbsp; &nbsp;<span
              v-if="numerror > 0"
              class="text-danger"
              >ข้อมูลฟอร์แมตไม่ถูกต้อง ({{ numerror }}) รายการ
            </span>
          </p>
        </div>
      </div>
      <div class="col-12">
        <b-table
          :empty-text="'ไม่พบข้อมูล / No Data'"
          :fields="fields"
          :items="items"
          :current-page="page_num"
          :per-page="page_size"
          :tbody-tr-class="rowClass"
          outlined
          hover
          show-empty
          responsive
          class="font-0-8s"
        >
          <template #cell(error)="row">
            <span v-if="row.item.error.length > 0" class="text-danger">{{
              row.item.error.join(` / `)
            }}</span>
          </template>
          <template #cell(Action)="row">
            <span
              type="button"
              class="text-twd"
              v-html="$svg_icon.remove"
              @click="remove(row)"
            ></span>
          </template>
        </b-table>
      </div>
      <div class="col-12">
        <div class="row justify-content-end">
          <div class="col-6 col-xl-1 input-group-prepend input-group-sm">
            <select class="custom-select" v-model="page_size">
              <option v-for="(e, i) in page_size_list" :key="i" :value="e">
                {{ e }}
              </option>
            </select>
          </div>
          <div class="col-6 col-xl-3">
            <b-pagination
              v-model="page_num"
              :total-rows="totalRows"
              :per-page="page_size"
              align="fill"
              size="sm"
              class="my-0"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DeliveryMethodUpload",
  data() {
    return {
      file: require("@/files/DeliveryMethod_Exam.xlsx"),
      totalRows: 0,
      numerror: 0,

      msserror: [],
      items: [],
      itemerror: [],
      page_size: 10,
      page_size_list: [10, 15, 20, 25, 30],
      page_num: 1,
      fields: [
        { key: "skCode", label: "skCode" },
        { key: "3PL", label: "3PL" },
        { key: "Express_Delivery", label: "Express_Delivery" },
        {
          key: "Own_Fleet",
          label: "Own_Fleet",
        },
        {
          key: "Click_Collect",
          label: "Click&Collect",
        },

        { key: "error", label: "error" },
        {
          key: "Action",
          label: "",
          thStyle: { width: "5%" },
        },
      ],
    };
  },

  mounted() {},
  methods: {
    async remove(data) {
      let { item, index } = data;
      let { skCode } = item;

      let confirm = await this.$serviceMain.showConfirmAlert(
        this,
        `คุณต้องการลบ ${skCode} / You need to delete ${skCode}?`
      );
      if (confirm) {
        this.items.splice(index, 1);
        let findindex = this.itemerror.findIndex((e) => e == item);

        if (findindex !== -1) {
          this.itemerror.splice(findindex, 1);
          this.numerror = this.itemerror.length;
        }
        this.totalRows = this.items.length;
      }
    },
    async Upload() {
      try {
        if (this.items.length === 0) throw `กรุณาเลือกไฟล์ที่ต้องการอัพโหลด`;
        if (this.itemerror.length > 0) {
          throw `กรุณาเเก้ไขความผิดพลาด ${this.itemerror.length} รายการ เเล้วอัพโหลดไฟล์ใหม่อีกครั้ง / Please correct the error ${this.itemerror.length} item and upload the file again`;
        }
        let confirm = await this.$serviceMain.showConfirmAlert(
          this,
          "คุณต้องการบันทึกหรือไม่? / Do you want to save?"
        );
        if (confirm) {
          let data = this.items;
          // console.log(data[0]);
          // console.log(JSON.stringify(data[0]).length);
          let getAPI = await this.$serviceAPI.call_API(
            "post",
            "product/updatedeliverymethod",
            { jdata: data },
            1
          );
          this.$serviceMain.showSuccessAlert(
            this,
            "อัพโหลดข้อมูลสำเร็จ / Complete"
          );
          this.ClearTable();
        }
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    async Clear() {
      let confirm = await this.$serviceMain.showConfirmAlert(
        this,
        "คุณต้องการยกเลิกหรือไม่? / Do you want to cancel?"
      );
      if (confirm) {
        this.ClearTable();
      }
    },
    async ClearTable() {
      this.$refs.fileupload.value = null;
      this.file = null;
      this.items = [];
      this.totalRows = this.items.length;
      this.numerror = 0;
    },
    async onChange(event) {
      try {
        let readfile = await this.$serviceMain.ReadExcel(event.target.files[0]);
        let list = [];
        this.itemerror = [];
        let listerror = [];
        this.numerror = 0;

        this.totalRows = 0;
        if (readfile.length > 0) {
          let { id } = this.$store.getters["loginActions/GET_USER"];
          for await (var i of readfile) {
            let { skCode } = i;
            i.deliverymethodCC = "";
            i.deliverymethod = [];
            i.updateby = id;
            i.error = [];
            if (!!!skCode) {
              i.error.push("no skCode");
            }

            if (
              !!!i["3PL"] &&
              !!!i.Express_Delivery &&
              !!!i.Own_Fleet &&
              !!!i.Click_Collect
            ) {
              i.error.push(
                "Delivery method 3PL,Express Delivery ,Own Fleet must select at least 1 option"
              );
            }

            // if (!!!i.FRE && !!!i["3PL"]) {
            //   i.error.push("Not found delivery service FRE or 3PL");
            // }

            if (
              (!!i["3PL"] && String(i["3PL"]).toUpperCase() !== "Y") ||
              (!!i.Express_Delivery &&
                String(i.Express_Delivery).toUpperCase() !== "Y") ||
              (!!i.Own_Fleet && String(i.Own_Fleet).toUpperCase() !== "Y") ||
              (!!i.Click_Collect && String(i.Click_Collect).toUpperCase() !== "Y")
            ) {
              i.error.push("Please Enter 'Y' that service you select");
            }

            // ------------------ check err ---------------
            if (i.error.length > 0) {
              let data = {
                ...i,
              };
              data["3PL"] = data["3PL"] || "";
              data.Express_Delivery = data.Express_Delivery || "";
              data.Own_Fleet = data.Own_Fleet || "";
              data.Click_Collect = data.Click_Collect || "";

              // note when emtry in excel will not have that key
              listerror.push(data);
              this.numerror++;
            } else {
              let data = {
                ...i,
              };
              data["3PL"] = data["3PL"] || "";
              data.Express_Delivery = data.Express_Delivery || "";
              data.Own_Fleet = data.Own_Fleet || "";
              data.Click_Collect = data.Click_Collect || "";


              // note when emtry in excel will not have that key
              if (!!data["3PL"] && String(data["3PL"]).toUpperCase() === "Y") {
                data.deliverymethod.push("3PL");
              }

              if (
                !!data.Express_Delivery &&
                String(data.Express_Delivery).toUpperCase() === "Y"
              ) {
                data.deliverymethod.push("EXP");
              }

              if (
                !!data.Own_Fleet &&
                String(data.Own_Fleet).toUpperCase() === "Y"
              ) {
                data.deliverymethod.push("OWF");
              }

              if (
                !!data.Click_Collect &&
                String(data.Click_Collect).toUpperCase() === "Y"
              ) {
                data.deliverymethod.push("CAC");
              }


              data.deliverymethodCC = data.deliverymethod.join("|");
              list.push(data);
            }
          }
        }

        this.items = [...listerror, ...list];
        this.itemerror = listerror;
        this.totalRows = this.items.length;
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    rowClass(item, type) {
      if (!item || type !== "row") return;

      if (item.error.length > 0) return "table-danger ";
    },
  },
};
</script>

<style></style>
